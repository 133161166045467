import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  HStack,
  Spacer,
  Text,
} from "@chakra-ui/react";

export default function Dashboard() {
  return (
    <Container maxW={"container.xl"}>
      <HStack py={8}>
        <Breadcrumb>
          <BreadcrumbItem isCurrentPage>
            <Text>Dashboard</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <Spacer />
      </HStack>
    </Container>
  );
}
